import React, { Component } from 'react'
import Header from '../Header'
import './XXw.css'

class XXw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyXXw">
                    <p className="textXXw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default XXw