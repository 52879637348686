import React, { Component } from 'react'
import Header from '../Header'
import './XIIw.css'

class XIIw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyXIIw">
                    <p className="textXIIw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default XIIw