import React, { Component } from 'react'
import Header from '../Header'
import './Xw.css'

class Xw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyXw">
                    <p className="textXw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default Xw