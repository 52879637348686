import React, { Component } from 'react'
import Header from '../Header'
import './XVIIw.css'

class XVIIw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyXVIIw">
                    <p className="textXVIIw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default XVIIw