import React, { Component } from 'react'
import Header from '../Header'
import './Iw.css'

class Iw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyIw">
                    <p className="textIw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default Iw