import React, { Component } from 'react'
import Header from '../Header'
import './IXw.css'

class IXw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyIXw">
                    <p className="textIXw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default IXw