import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"
import './App.css'

class App extends Component {
  
    render () {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Jeux Olymiques</title>
                    <link rel="canonical" href="https://jo.guillaumequeste.fr" />
                </Helmet>
                <div className="bodyApp">
                    <div className="divApp">
                        <Link to="/summer" className="lienSummer">
                            <div className="divSummer">
                                <span className="linkAccueilSummer">Eté</span>
                            </div>
                        </Link>
                        <Link to="/winter" className="lienWinter">
                            <div className="divWinter">
                            <span className="linkAccueilWinter">Hiver</span>
                            </div>
                        </Link>
                        <Link to="/antiquity" className="lienAntiquity">
                            <div className="divAntiquite">
                            <span className="linkAccueilAntiquity">Antiquité</span>
                            </div>
                        </Link>
                        <div className="anneaux"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default App