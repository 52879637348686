import React, { Component } from 'react'
import Header from '../Header'
import './IIIw.css'

class IIIw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyIIIw">
                    <p className="textIIIw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default IIIw