import React, { Component } from 'react'
import Header from '../Header'
import './XXIIw.css'

class XXIIw extends Component {
  
    render () {
        return (
            <div>
                <Header />
                <div className="bodyXXIIw">
                    <p className="textXXIIw">A venir...</p>
                </div>
            </div>
        )
    }
}

export default XXIIw